body {
  font-family: "Roboto", sans-serif !important;
  margin: 0;
}

* {
  outline: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  color: #1a1c1e;
}

a {
  text-decoration: none !important;
}

.navbar-brand a {
  box-sizing: border-box;
}

button {
  border: 0;
  outline: none !important;
}

section {
  padding-top: 100px;
}

h4 {
  color: #1a1c1e;
}

.btn:focus,
.btn.focus {
  box-shadow: none !important;
}

::selection {
  background-color: #1a1c1e;
  color: #fff;
}

.main_center_box h6 {
  color: #1a1c1e;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 0;
}

.main_center_box p {
  color: #42474e;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: center;
  padding: 5px 0 16px;
  margin-bottom: 0.7rem;
}

.main_form_box {
  width: 100%;
  border: 1px solid #72777f;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  align-items: center;
  color: #42474e;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
  height: 45px;
}

.main_form_box .form-group {
  width: 100%;
  margin-bottom: 0;
}

.main_form_box svg {
  margin-right: 11px;
}

.input-field {
  width: 100%;
  border: none;
  background: none;
}

.main_form_box .form-control {
  background: transparent;
  border: unset;
  padding: 0;
  color: var(--M3-sys-light-on-surface-variant, #42474e);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  box-shadow: unset;
}

.main_form_box .input-field::placeholder {
  color: #42474e;
  font-size: 16px;
  font-weight: 400;
}

.support-input-main {
  border: 1px solid #75777f;
  border-radius: 4px;
  padding: 0 16px;
  width: 400px;
}

.bttn_continue {
  display: flex;
  justify-content: flex-end;
}

.bttn_continue button.btn_continue {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  transition: 0.3s all;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  border-radius: 100px;
  background: #0059c7;
  margin: 15px 0;
  padding: 10px 24px;
  border: 1px solid transparent;
}

.bttn_continue button.btn_continue:hover {
  color: #0059c7;
  background: transparent;
  border-color: #0059c7;
}

.link_sec a {
  color: var(--M3-sys-light-secondary, #51606f);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin: 0 10px;
}

.link_sec a:hover {
  color: #00639b;
  text-decoration: none;
}

/* forgotpassword */
.link_pasword {
  color: #0062a0;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-decoration: unset;
}

.input {
  border: unset;
  width: 100%;
  max-width: 300px;
  appearance: none;
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  background-color: transparent;
  margin-left: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input:disabled {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #1a1c1e;
}

.mian_select_too {
  border-radius: 8px;
  border: 1px solid var(--M3-sys-light-outline, #72777f);
  background: var(--M3-sys-light-surface, #fcfcff);
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin: 0.8rem auto 1.5rem auto;
  justify-content: center;
  width: 100%;
}

.mian_select_disabled_email {
  border-radius: 8px;
  border: 1px solid var(--M3-sys-light-outline, #72777f);
  background: var(--M3-sys-light-surface, #fcfcff);
  display: flex;
  align-items: center;
  padding: 6px 8px;
  margin: 0.8rem auto 1.5rem auto;
  justify-content: center;
  width: 100%;
}

.signin_disabled_email {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* verification */
.verification_sec .form-group {
  border-radius: 4px;
  border: 1px solid var(--M3-sys-light-outline, #75777f);
  margin-bottom: 0;
}

.verification_sec .form-group .otpBox {
  width: 55px;
  height: 55px;
  border: unset;
  border-right: 1px solid #75777f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  border-radius: 4px;
  text-align: center;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.verification_sec .form-group .otpBox:last-child {
  border-right: unset;
}

.verification_sec .key_word {
  max-width: 50px;
  width: 100%;
}

/* National Trust Registry */
.main_center_box h5 {
  color: var(--M3-sys-light-on-surface, #1b1b1e);
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 1.3rem;
}

.main_center_box h4 {
  color: var(--M3-sys-light-on-surface, #1b1b1e);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
}

.verify-input input:first-child {
  border-left: 1px solid #75777f !important;
  color: red;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.verify-input input:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.cursor-pointer {
  cursor: pointer;
}

.main_center_box {
  border-radius: 28px;
  background: linear-gradient(0deg,
      rgba(0, 99, 155, 0.05) 0%,
      rgba(0, 99, 155, 0.05) 100%);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  padding: 24px;
  margin: 2rem 0;
}

.link-text-color {
  color: #725574;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.search-input-field {
  border-radius: 4px;
  border: 1px solid #75777f;
  color: #1b1b1e;
  padding: 8px 25px;
  padding-left: 9px;
  width: 265px;
}

.search-btn {
  border: none;
  background-color: transparent;
  top: 6px;
  right: 0;
}

.tab-link {
  padding: 8px 63px;
  cursor: pointer;
  text-align: center;
  color: #acabaf;
  border-bottom: 2px solid rgba(172, 171, 175, 0.75);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.tab-link.active {
  color: #0059c7;
  border-bottom: 2px solid #0059c7;
}

.profile_tab_link {
  padding: 8px 35px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  border-radius: 100px;
  border: 1px solid #5f6368;
}

.profile_tab_link.active {
  border-radius: 100px 0px 0px 100px;
  border: 1px solid #e9eefb;
  background: #e9eefb;
  color: #0059c7;
}

.profile_tab_link.active0 {
  border-radius: 100px 0px 0px 100px;
  border: 1px solid #5f6368;
  color: #979797;
}

.profile_tab_link.Inactive {
  border-radius: 0px 100px 100px 0px;
  border: 1px solid #5f6368;
  color: #979797;
}

.profile_tab_link.Inactive0 {
  border-radius: 0px 100px 100px 0px;
  border: 1px solid #e9eefb;
  background: #e9eefb;
  color: #0059c7;
  border-left: 0;
}

.reset_password_ydal {
  border-radius: 25px;
  background: #e8ebf4;
  padding: 30px 100px;
}

.reset_password_success_modal {
  border-radius: 25px;
  background: #e8ebf4;
  padding: 30px 60px;
}

.table .mHisf {
  font-size: 16px;
}

.btn-transparant {
  border-radius: 100px !important;
  border: 1px solid #75777f !important;
  font-weight: 500 !important;
  padding: 10px 24px !important;
  background-color: transparent !important;
  color: #0059c7 !important;
  font-size: 14px !important;
  font-style: normal;
}

.btn-thm {
  border-radius: 100px !important;
  background: #0059c7 !important;
  color: #fff !important;
  padding: 10px 24px !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0.1px !important;
}

.active-sidebar,
.sidebar-nav:hover {
  border-radius: 100px;
  background: #dbe2f9;
}

.SiteBtn {
  padding: 10px 24px !important;
  border-radius: 100px;
  background: #0059c7 !important;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.SiteBtn2 {
  padding: 10px 12px;
  color: #0059c7;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1px;
  cursor: pointer;
}

.SiteBtn3 {
  border-radius: 100px;
  background: #0059c7;
  padding: 24px;
  color: #fff;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.SiteBtn4 {
  border-radius: 100px;
  background: #0059c7;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.4px;
  width: 70%;
  padding: 15px;
}

.AccountType input[type="radio"] {
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-clip: content-box;
  border: 3px solid #42474e;
  margin-right: 10px;
}

.AccountType input[type="radio"]:checked {
  background-color: #42474e;
  padding: 3px;
  border: 3px solid #42474e;
}

.subscriptionMainTitle {
  color: #262626;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
}

.subscriptionMainTitle2 {
  color: #000000;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 40px;
}

.plan {
  box-shadow: 0 4px 6px #aaa;
}
.plan.active {
  border: 3px solid #0062A0;
}
.plan-title {
  color: #00639B;
  width: 245px;
}
.plan button {
  background: #7DAAED;
  min-width: 240px;
}
.plan button:hover {
  background: #0062A0;
  color: #fff;
}
.plan-price {
  width: 285px;
}
.plan-price .amount {
  font-size: 57px;
  line-height: 64px;
}

.sign-up-card-container .TitleTxt1 {
  color: #1b1b1e;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
}

.sign-up-card-container .TitleTxt2 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.sign-up-card-container .TitleTxt3 {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sign-up-card-container .card-check-text {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.lastUpdatedText {
  color: #1b1b1e;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.main_navigation_Sec .container-fluid {
  --bs-gutter-x: 2.5rem;
}

.main_navigation_Sec .navbar {
  --bs-navbar-padding-y: 0.6rem;
}

.conten-div {
  padding: 1.6rem 1.2rem;
}

.TableData .rdt_Table .rdt_TableHead,
.TableData .rdt_Table .rdt_TableBody {
  padding: 0rem 0rem;
}

.TableData .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell,
.TableData .rdt_Table .rdt_TableHeadRow .rdt_TableCol {
  padding-right: 12px;
  padding-left: 12px;
  width: 0;
}

.TableData .rdt_Table .rdt_TableHeadRow .rdt_TableCol_Sortable {
  color: #1b1b1e;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.TableData .rdt_Table .rdt_TableBody .rdt_TableCell {
  color: var(--M3-sys-light-on-surface, #1b1b1e);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  letter-spacing: 0.5px;
}

.trustnameinput {
  color: #001943 !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 22.93px !important;
  letter-spacing: 0.478px !important;
  border: 0px !important;
  padding-left: 0px !important;
}

.right_top_ststus .css-13cymwt-control,
.right_top_ststus .css-t3ipsp-control {
  border-color: unset;
  border-radius: unset;
  border-style: unset;
  border-width: unset;
  padding: 6px 8px;
}

.right_top_ststus .css-1dimb5e-singleValue {
  color: var(--M3-sys-light-on-surface, #1a1c1e);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.main_form .form-control:focus {
  box-shadow: unset;
  border-color: unset;
}

#accordion .card-body .submit {
  margin-bottom: 1rem;
  align-items: center;
}

.disabled-button {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #ccc;
  color: #666;
}

.main_group1 {
  padding: 8px 10px;
  position: relative;
  margin: 0.5rem 0;
  background-color: #e9eefb;
  border-radius: 3.822px;
  border: 0.955px solid #fff;
}

.main_group1 input,
.main_group1 input:disabled,
.main_group1 input:focus {
  background-color: #e9eefb;
  color: #001943;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  border: 0;
  padding: 0px 4px;
  box-shadow: unset;
}

.main_group1 label {
  color: #44464e;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  position: absolute;
  top: -14%;
  padding: 0 4px;
  background-color: #ffffff;
  margin: 0;
}

.file_sub_group {
  margin-top: 8px;
}

.file_sub_group label {
  background-color: #e9eefb;
  color: #3f76c8;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  width: 100%;
}

.file_sub_group label:after {
  right: 5px !important;
}

.SSN-input-message {
  color: #42474e !important;
  font-size: 10px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  display: flex;
  padding: 0px !important;
  margin: 0px !important;
  margin-bottom: -21px !important;
}

.modal-content {
  color: var(--bs-modal-color);
  background-color: none;
  background-clip: none;
  border: none;
  border-radius: none;
  position: relative !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  width: 100% !important;
  pointer-events: auto !important;
  outline: 0 !important;
}

.notename-textbox {
  border-radius: 4px !important;
  border: 1px solid #42474e !important;
  color: #42474e !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0.5px !important;
  padding: 15px 16px !important;
  height: auto;
}

.form-control:focus {
  box-shadow: unset !important;
}

.post-btn {
  padding: 10px 24px !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0.1px !important;
}

.post-btn:disabled,
.disb-btn:disabled {
  background: rgba(28, 27, 31, 0.12) !important;
  color: #1a1c1e !important;
}

.HighlightDot {
  position: absolute;
  top: 1px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  border: 2px solid #42474e;
  padding: 3px;
}

.HighlightDot::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #42474e;
  border-radius: 50px;
}

.mian_check_box_sec {
  max-height: 260px;
  overflow-y: auto;
}

.sstn-error {
  position: relative;
  top: 15%;
}

.main-req-supp-div {
  border-radius: 12px;
  background: #fdfbff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3),
    0px 8px 12px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  width: 450px;
  height: 650px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
}

.support-head {
  color: #1b1b1e;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
}

.forgot-box {
  background-color: #f0f3fc;
  border-radius: 20px;
  padding: 40px 40px !important;
}

.forgot-box input {
  border-radius: 6px;
}

.support-modal .modal-content {
  background-color: #e9eefb;
  border-radius: 20px;
}

.textarea-support {
  border-radius: 5px;
  padding: 10px;
}

.dis-text-plans {
  color: var(--M3-sys-light-primary, #00639b);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  position: absolute;
  width: 100%;
  bottom: -3px;
}

.required-star-text {
  color: red;
}

.supprt-link-sidebar {
  position: relative;
}

.supprt-link-sidebar span {
  position: absolute;
  bottom: 0;
  right: 0;
}

.support-text {
  bottom: 30px;
  left: 80px;
}

.support-link-text {
  color: #bfc6dc;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-decoration: none !important;
}

.support-link-text:hover {
  color: #bfc6dc;
}

.trust-permission-tbl .lnOUep {
  font-size: 16px;
  font-weight: 400;
  color: #1b1b1e;
}

.trust-permission-tbl .rdt_TableCell {
  font-size: 14px;
  font-weight: 400;
  color: #1b1b1e;
}

.react-switch-bg {
  border: 2px solid transparent;
}

.trust-permission-tbl .inactive-border .react-switch-bg {
  border: 2px solid #72777f;
}

.trust-permission-tbl label {
  margin-bottom: 0px;
}

.CongratulationsPopup .modal-content {
  background-color: transparent;
  border: 0;
}

.text-red {
  color: red;
}

.text-green {
  color: green;
}

.see-attachment-text {
  font-size: 16px !important;
  color: #3f76c8 !important;
  font-weight: 500 !important;
}

.see-upload-attachment {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.thank-you-modal .modal-content {
  background-color: #EFF4FA;
  padding: 20px 40px;
}

.SiteBtnThanks {
  padding: 12px 30px !important;
  border-radius: 0;
  background: #0059c7 !important;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.text-thanks {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.25px;
  text-align: center;
  color: #44464E;

}

.sign-up-form-text {
  font-weight: 300;
  font-size: 18px;
}

.signup-form-pwd-text {
  color: #44464EBF;
  font-size: 15px;
}

.Invite-title h3 {
  color: #000;
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 0.1px;
}


.card-slider {
  max-width: 800px;
  /* Set the maximum width of the slider */
  margin: 0 auto;
  /* Center the slider horizontally */
}

.card {
  width: 400px;
  /* Set the width of each card */
  margin: 0 10px;
  /* Add margin between cards */
}

.card img {
  width: 100%;
  /* Make sure the image fills the entire card */
}


.custom-prev-arrow {
  background: none;
}

.custom-next-arrow {
  background: none;
}

.subscriptionLogout {
  position: absolute;
  top: 30px;
  right: 130px
}
