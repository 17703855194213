code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}


:root {
  --thm-black: #000000;
  --thm-gray: #44464E;
  --thm-dark-gray: #3C4043;
}

.thm-gray {
  color: var(--thm-gray);
}

.thm-black {
  color: var(--thm-black);
}

.thm-graydark {
  color: var(--thm-dark-gray);
  ;
}

.f4bold {
  font-weight: 400;
}

.f6bold {
  font-weight: 600 !important;
}

.fs16 {
  font-size: 16px;
}

.fs20 {
  font-size: 20px;
}

.error {
  color: red;
  font-size: 14px;
}

.btn-div {
  text-align: right;
}

.btn-thm {
  background: #00639b;
  border-radius: 50px;
  color: #fff !important;
  font-size: 14px;
  padding: 5px 15px;
}

.card-check-text {
  color: #51606f;
  font-weight: 500;
  font-size: 20px;
}

.sign-up-card-container {
  background-color: #eff4fa;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
    0px 4px 8px 3px rgba(0, 0, 0, 0.15);
  border-radius: 24px;
  height: 31.4rem;
}

.payment-card-circle {
  width: 28px;
  height: 28px;
  border-radius: 50px;
  background: #0059c7;
}

.payment-card-circle span {
  padding: 5px;
}

.payment-card-inner-circle {
  height: 10px;
  width: 10px;
}

.card-type-container {
  border-radius: 5px;
  background: #f2f2f2;
}

.custom-input-container {
  flex: 1;
}

.custom-input-main {
  position: relative;
  display: inline-block;
  padding: 0;
  background-color: #eff4fa;
  flex: 1;
}

.custom-input-label {
  padding: 10px;
  pointer-events: none;
  position: absolute;
  left: 10px;
  top: 10px;
  transition: 0.2s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 0.5;
  background-color: #eff4fa;
  font-weight: 400;
  color: #51606f;
}

.login_icon {
  background: #fff;
  height: 40px;
  width: 40px;
  display: grid;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.app_complete {
  border-radius: 12px;
  background: var(--M3-sys-light-surface, #fdfbff);
  padding: 10px 20px;
  margin: 2rem 0;
  border-radius: 12px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3),
    0px 8px 12px 6px rgba(0, 0, 0, 0.15);
}

.main_center_sec {
  display: grid;
  align-items: center;
}

.back_btn {
  border: 1px solid;
}

.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_form {
  border-radius: 4px;
  border: 1px solid var(--M3-sys-light-outline, #75777f);
  padding: 8px 10px;
  position: relative;
  margin: 1rem 0;
}

.main_form .form-group {
  margin-bottom: 0;
  overflow: hidden;
}

.main_form label {
  color: var(--M3-sys-light-on-surface-variant, #44464e);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  position: absolute;
  top: -22%;
  padding: 3px;
  margin-bottom: 0;
  background: #eff4fa;
  width: max-content;
  left: 12px;
}

.custom-input-field {
  padding: 10px;
  background-color: #eff4fa;
  border: 1.5px solid #75777f;
  border-radius: 4px;
}

.custom-input-field:focus+label,
input:not(:placeholder-shown)+label {
  opacity: 1;
  transform: scale(0.75) translateY(-70%) translateX(-14px);
  color: #44464e;
}

.thank-you-main {
  max-width: 730px;
  max-height: 300px;
  background-color: #eff4fa;
}

.ntr-sidebar-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  color: #bfc6dc;
}

.profile_section_label {
  color: var(--M3-sys-light-on-surface-variant, #44464e);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

#backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.29);
}

.spinner-border {
  display: block;
  position: fixed;
  top: calc(50% - 29px);
  right: calc(50% - 29px);
  color: #0059c7;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.profile_title_grid {
  border-bottom: 0.5px solid #1f1f1f6b;
}

.top_grid_sec .title_grid p {
  margin: 0.5rem 0 1.5rem 0;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.287px;
  letter-spacing: 0.382px;
}

.profile_main_part_section {
  background: #fff;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  padding: 16px;
}

.profile_main_sec_detail {
  background: #fff;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  padding: 16px 8px;
}

.main_grid_top.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 10px;
}

.main_grid_top.grid-container.edit_grid_sec .form-group {
  box-shadow: unset;
  border-radius: 4px 4px 0px 0px;
  background: #e1e2ec;
  padding: 0 10px;
  border-bottom: 1px solid #1a1c1e;
}

.main_grid_top.grid-container.edit_grid_sec .form-group .form-control {
  background: transparent;
  height: auto;
  padding: 0 0 0.5rem 0;
  border: none;
  box-shadow: none;
}

.files-wr input {
  display: none;
}

.upload_file_trust {
  border-radius: 4px;
  border: 1px solid #75777F !important;
  padding: 15px 10px;
}

.files-wr label {
  color: #44464E !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0.5px !important;
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition: all 0.2s;
}

.files-wr label:after {
  content: url("../public/assets/images/trailing-icon.png");
  border-radius: 5px;
  position: absolute;
  top: 0;
  right: -47px;
  font-size: 18px;
  line-height: 32px;
  color: #fff;
  text-align: center;
  transition: all 0.2s;
}

.files-wr .one-file~.one-file label {
  display: none;
}

.files-wr .one-file.error {
  border: none;
}

.files-wr .one-file.error .file-name {
  color: #44464e;
}

.files-wr .file-item {
  position: relative;
  margin-top: 4px;
  display: flex;
  align-items: center;
}

.files-wr .file-item.hide-btn {
  display: none;
}

.files-wr .file-name {
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
}

.files-wr .btn-del-file {
  margin-left: 5px;
  line-height: 16px;
  border-radius: 3px;
  color: #3f76c8;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
}

.profile_main_part_user_detail {
  color: var(--M3-sys-light-on-primary-container, #001943);
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0.5px;
  width: 270px;
  word-wrap: break-word;
  overflow: hidden;
}

.profile_main_part_user_detail_profile {
  color: #001943;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 0.5px;
  width: 200px;
  word-wrap: break-word;
  overflow: hidden;
}

.nav-item a.nav-link {
  color: #acabaf;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  border-bottom: 1px solid rgba(172, 171, 175, 0.75);
  width: 150px;
  border-radius: 0;
}

.nav-item a.nav-link.active {
  color: #0059c7;
  border-color: #0059c7;
  background-color: transparent;
}

.accordian_Sec #accordion .docCard {
  margin-bottom: 1rem;
  border: transparent;
  color: #fff;
  border-radius: 0px;
  background: transparent;
  box-shadow: 30px 30px 60px #0000010d;
}

.accordian_Sec #accordion .docCard .btn.btn-link {
  border-radius: 16px 16px 0px 0px;
  background: linear-gradient(0deg, rgba(0, 99, 155, 0.08) 0%, rgba(0, 99, 155, 0.08) 100%), #fcfcff;
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  padding: 1.2rem 1rem;
  width: 100%;
  text-decoration: unset;
  text-align: left;
}

.btn-link:before {
  float: right !important;
  font-family: FontAwesome;
  content: "\f0de" !important;
  padding-right: 5px;
  color: #1a1c1e;
}

.btn-link:before {
  float: right !important;
  font-family: FontAwesome;
  content: "\f0de" !important;
  padding-right: 5px;
  color: #1a1c1e;
  font-size: 30px;
}

.btn-link.collapsed:before {
  float: right !important;
  content: "\f0d7" !important;
  color: #1a1c1e;
  font-size: 30px;
}

.mian_check_box_sec .container {
  margin-bottom: 3rem;
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: var(--M3-ref-secondary-secondary20, #233240);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.mian_check_box_sec .container p {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.toggle_sec {
  margin: 2.5rem 0rem;
}

.toggle_sec h5 {
  color: #44464e;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.toggle_sec p {
  color: var(--M3-sys-light-on-surface-variant, #44464e);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.toggle_sec .switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 28px;
  margin-left: 1rem;
  margin-bottom: 0;
}

.toggle_sec .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle_sec .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background: #e1e2ec;
  border: 2px solid #44464e;
}

.toggle_sec .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: #44464e;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.toggle_sec input:focus+.slider {
  box-shadow: 0 0 1px #44464e;
}

.toggle_sec input:checked+.slider {
  background-color: #44464e;
}

.toggle_sec .slider.round {
  border-radius: 34px;
}

.toggle_sec .slider.round:before {
  border-radius: 50%;
}

.toggle_sec input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background: #fff;
}

.accordian_Sec #accordion .card .btn.btn-link {
  font-size: 22px;
  line-height: 22px;
  padding: 27px 16px;
}

.main_grid_top.grid-container.edit_grid_sec .form-group label {
  margin-bottom: 0;
}

.color_change .btn_Grantor {
  color: #0059c7 !important;
  opacity: 1 !important;
}

.profile_edit_btn {
  background: #0059c7;
  padding: 9.554px 22.93px;
}

.profile_save_btn {
  background: #e9eefb;
  padding: 9.554px 22.93px;
}

.payment_main_form {
  border-radius: 4px;
  border: 1px solid #75777f;
  padding: 8px 10px;
  position: relative;
  margin: 2rem 0 0 0;
}

.payment_main_form .form-group {
  margin-bottom: 0;
}

.payment_main_form label {
  color: #44464e;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  position: absolute;
  top: -22%;
  padding: 3px;
  margin-bottom: 0;
  background: #eff4fa;
  width: max-content;
  left: 15px;
}

.payment_main_form .payment_form_control {
  color: #51606f;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  background: transparent;
  border: unset;
  box-shadow: unset;
  padding: 8px;
}

.mian_grid_top.grid-container {
  grid-template-columns: auto auto auto auto;
  gap: 10px;
}

.mian_part_sec {
  background: #fff;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  padding: 16px 8px;
  margin: 28px 0;
}

.mian_part_sec .title_row p {
  color: #1b1b1e;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 0px;
}

.mian_part_sec .title_row {
  border-bottom: 0.5px solid #9aa0a7;
  padding: 12px;
  margin-bottom: 20px;
}

.mian_part_sec .title_row .btn_Grantor {
  color: #0059c7;
  border-radius: 100px;
  background: #e9eefb;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  padding: 10px 24px;
}

.mian_part_sec .title_row .btn_Grantor.active {
  background: #0059c7;
  border: 1px solid transparent;
  color: #fff !important;
  opacity: 1;
}

.open_chat_uer_menu {
  position: absolute;
  top: 92px;
  left: 16px;
  z-index: 1;
}

.title_bar {
  background: var(--M3-read-only-light-surface2,
      linear-gradient(0deg,
        rgba(0, 89, 199, 0.08) 0%,
        rgba(0, 89, 199, 0.08) 100%),
      #fdfbff);
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  padding: 1rem 1.2rem;
}

.title_bar h5 {
  color: #1b1b1e;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 0;
}

.title_bar p span {
  color: #3eaf3f;
}

.title_bar svg {
  width: 25px;
  height: 25px;
}

.btn_sec button.btn_export {
  border: 1px solid #75777f;
  color: #0059c7;
}

.btn_sec button.add_trust {
  background: #0059c7;
  color: #fff;
}

.btn_sec button.btn_export,
.btn_sec button.add_trust {
  border-radius: 100px;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  padding: 10px 24px;
  transition: 0.5s all;
}

.btn_sec button.btn_export:hover {
  border-color: #0059c7;
}

.btn_sec button.add_trust:hover {
  background-color: transparent;
  color: #0059c7;
  border: 1px solid #0059c7;
}

.edit_Sec p {
  color: var(--M3-sys-light-on-surface-variant, #44464e);
  font-size: 16px;
  margin-bottom: 0.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 15.287px;
  letter-spacing: 0.382px;
}

.edit_Sec h6 {
  color: #001943;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.93px;
  letter-spacing: 0.478px;
}

.edit_btnn button.btn_edit {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  border-radius: 95.544px;
  background: #0059c7;
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
}

.edit_btnn button.btn_edit:hover {
  color: #0059c7;
  border: 1px solid #0059c7;
  background: transparent;
}

.right_top_ststus .form-control {
  font-size: 14px;
  line-height: 22px;
  padding: 0.8rem 0.8rem;
}

.right_top_ststus label {
  font-size: 12px;
  line-height: 16px;
  top: -19%;
  left: 15%;
}

.btm_details_sec h6 {
  font-size: 30px;
  line-height: 26.752px;
}

.change-plan-text {
  position: absolute;
  bottom: 30px;
  text-align: center;
  left: 0;
}

.inner-header-title {
  color: #1b1b1e;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.edit_Sec p {
  color: #44464e;
  font-size: 16px;
  margin-bottom: 0.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 15.287px;
  letter-spacing: 0.382px;
}

.edit_Sec h6 {
  color: #001943;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.93px;
  letter-spacing: 0.478px;
}

.edit_btnn button.btn_edit {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  border-radius: 95.544px;
  background: #0059c7;
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
}

.edit_btnn button.btn_edit:hover {
  color: #0059c7;
  border: 1px solid #0059c7;
  background: transparent;
}

.right_top_ststus {
  border: 2px solid #00639b;
  border-radius: 4px;
}

.right_top_ststus .form-group {
  margin-bottom: 0;
}

.right_top_ststus label {
  color: #00639b;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin-bottom: 0;
  position: absolute;
  top: -9px;
  left: 27px;
  background: #fff;
  padding: 2px;
  z-index: 1;
}

.right_top_ststus .form-control {
  border-radius: 4px 4px 0px 0px;
  color: #1a1c1e;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  border: unset;
  padding: 0.8rem 1rem;
  height: unset;
  box-shadow: unset;
}

.mian_grid_top.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 10px;
  padding: 0 12px;
}

.mian_grid_top.grid-container .form-group label {
  color: #44464e;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.mian_grid_top.grid-container .form-group .form-control {
  color: #001943;
  font-size: 16px;
  font-style: normal;
  background: transparent;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  border: unset;
  box-shadow: unset;
  padding: 0;
}

.popupTitle {
  color: #1B1B1E;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.1px;
}

.popupTitle2 {
  color: #1B1B1E;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.1px;
}

.main_form1 {
  border-radius: 4px;
  border: 1px solid #75777f;
  padding: 8px 10px;
  position: relative;
  margin: 1rem 0;
}

.main_form1 .form-group {
  margin-bottom: 0;
}

.main_form1 label {
  color: #44464e;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  position: absolute;
  top: -22%;
  padding: 3px;
  margin-bottom: 0;
  background: #fff;
  width: max-content;
  left: 12px;
}

.main_form1 .form-control {
  color: #51606f;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  background: transparent;
  border: unset;
  box-shadow: unset;
  padding: 3px;
}

.form-control {
  font-size: small;
}

.text-style {
  color: #1b1b1e;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 0;
}

.see-upload-attachment {
  font-size: 14px;
}

.gap-0 {
  gap: 0 !important;
}

.checkout-form h2 {
  font-family: 'Arial', sans-serif;
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.checkout-form .coupon-section {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.checkout-form .coupon-section button {
  width: 30%;
}

.checkout-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.checkout-form .checkout-header {
  display: flex;
  font-size: x-large;
  justify-content: space-between;
  line-height: normal;
}

.checkout-form .checkout-header > strong {
  cursor: pointer;
}

.checkout-form input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s;
  border: 1px solid #dee2e6 !important;
  border-radius: 12px;
  padding: 10px;
}

.checkout-form input[type="text"]:focus {
  border-color: #007bff;
  outline: none;
}

.checkout-form .card-section {
  margin-bottom: 20px;
  border: 1px solid #dee2e6 !important;
  border-radius: 12px;
  padding: 10px;
}

.checkout-form .error {
  color: red;
  margin-top: 10px;
}

.checkout-form button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.checkout-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.checkout-form button:hover:not(:disabled) {
  background-color: #0056b3;
}

.coupon-container {
  background-color: lightgrey;
  padding: 22px;
  border-radius: 16px;
}

.coupon-container button {
  background-color: #e51f09b4;
}

.coupon-container button:hover:not(:disabled) {
  background-color: #e51f09b4;
}

@media (max-width:1024px) and (min-width:767px) {
  .profile_main_part_user_detail_profile {
    font-size: 12px;
  }

  .mian_grid_top.grid-container .form-group .form-control {
    font-size: 15px;
  }

  .mian_grid_top.grid-container .form-group label {
    font-size: 14px;
  }

  .see-attachment-text {
    font-size: 13px !important;
  }

  .see-upload-attachment {
    font-size: 11px !important;
  }

  .main_group1 label {
    font-size: 11px;
  }
}

@media (min-width:991px) {
  .close-btn-chat {
    display: none;
  }
}

@media (max-width:991px) {
  .title_bar {
    padding-left: 45px;
  }

  .header-title {
    padding-left: 30px;
  }
}

@media (max-width:320px) {

  .tab-link.active {
    color: #0059c7;
    border-bottom: 2px solid #0059c7;
    padding: 8px 50px;
  }

  .tab-link {
    padding: 9px 45px;
    font-size: 15px;
  }

  .main_navigation_Sec h5 {
    font-size: small;
  }

  .profile_tab_link.Inactive {
    width: 105px;
  }

  .btn-transparant {
    padding: 0px 6px 6px !important;
    font-size: 13px !important;
    width: 59px !important;
  }

  .btn-thm {
    height: 49px !important;
    width: 110px !important;
    font-size: 12px !important;
    padding: 6px 6px 6px 8px !important;

  }

  .text-style {
    font-size: 13px;
    line-height: 16px;
  }

  .mian_part_sec .title_row p {
    font-size: 13px;
    line-height: normal;
  }

  .bttn_continue button.btn_continue {
    font-size: 11px;
    height: 58px;
  }

  .btn_back button.btn {
    font-size: 13px;
    padding: 13px 14px;
  }

  .main_chat .left_Side_chat {
    max-width: 74%;
  }

  .btn_sec button.add_trust {
    height: 40px;
    font-size: 12px;
    line-height: 36px;
    width: 97px;
    padding: 0px 5px 38px 4px;
  }

  .search-input-field {
    width: 180px;
  }

}


@media (max-width:424px) {
  .upload_file_trust {
    padding: 10px 0px;
  }

  .see-upload-attachment {
    font-size: 13px !important;
  }

  .title_bar h5 {
    font-size: 11px;
  }

  .title_bar {
    height: 88px;
  }

  .btn-transparant {
    font-size: 12px;
  }

  .files-wr label {
    color: #44464E !important;
    font-size: 10px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px !important;
    letter-spacing: 0.5px !important;
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: all 0.2s;
  }

  .see-attachment-text {
    font-size: 13px !important;
  }
}

@media (max-width:375) {
  .btn-transparant {
    padding: 3px 13px 6px !important;
    font-size: 14px !important;
    width: 95px !important;
    height: 49px;
  }

  .btn-thm {
    height: 51px !important;
    width: 134px !important;
    font-size: 14px !important;
    padding: 8px 15px 12px !important;
  }

  .text-style {
    font-size: 18px !important;
    line-height: 19px;

  }

}


@media (max-width:600px) and (min-width:425px) {

  .btn_sec button.add_trust {
    max-width: 132px;
    padding: 10px;
    height: 43px;

  }

  .title_bar {
    height: 88px;
  }

  .text-style {
    font-size: 17px;
    line-height: 22px;
  }

  .btn-transparant {
    padding: 6px 13px 6px !important;
    font-size: 14px !important;
    width: 86px !important;
    height: 50px;
  }

  .btn-thm {
    height: 51px !important;
    width: 134px !important;
    font-size: 14px !important;
    padding: 8px 15px 12px !important;
  }


  .search-input-field {
    width: 200px;
  }


  .files-wr label {
    color: #44464E !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px !important;
    letter-spacing: 0.5px !important;
    position: relative;
    display: inline-block;
    cursor: pointer;
    transition: all 0.2s;
  }
}

.skip-button {
  background: transparent !important;
  color: #1266a5;
  font-size: 16px;
  text-decoration: underline;
  font-weight: 500;
}

.skipbtn {
  position: absolute;
  right: 0;
  top: 0;
}

@media (max-width:424px) and (min-width:321px) {
  .text-style {
    font-size: 17px;
    line-height: 22px;
  }

  .btn-transparant {
    padding: 4px 6px 6px !important;
    font-size: 14px !important;
    width: 81px !important;
  }


  .btn-thm {
    height: 51px !important;
    width: 134px !important;
    font-size: 14px !important;
    padding: 8px 15px 12px !important;
  }

  .btn_sec button.btn_export,
  .btn_sec button.add_trust {
    font-size: 14px;
    line-height: 15px;
    padding: 10px 9px;
    height: 41px;
  }

  .bttn_continue button.btn_continue {
    padding: 8px 24px;
    line-height: 13px;
    height: 45px;
  }

  .mian_part_sec .title_row p {
    font-size: 17px;
  }

  .files-wr label {
    font-size: 13px !important;
    padding: 4px;
  }

  .search-input-field {
    width: 200px;
  }

  .tab-link {
    padding: 8px 55px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {

  .btn_bottm .back_btn button.btn,
  .btn_bottm button.btn_continue {
    padding: 10px 24px;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.signup-radio {
  position: relative;
  z-index: 11111111;
  bottom: 12px;
  left: 38px;
}

.form-check-input:checked {
  background-color: #0f1010 !important;
  border-color: #050505 !important;
  border-radius: 14px !important;
}

.form-check-input[type=checkbox] {
  border-radius: 1.25em !important;
}

.maingrantorverify {
  padding: 17px 0px 33px;
  margin: 5rem 0;
  background-color: #EFF4FA;
  border-radius: 6px;
}

.payment-btn {
  border: 1px solid #0059C7;
  border-radius: 100px;
  height: 40px;
  width: 206px;
  color: #0059C7;
}

.payment-btn:hover {
  background-color: #0059C7;
  color: white;
}

.Spouse-btn {
  border: 1px solid #0059C7;
  border-radius: 100px;
  height: 40px;
  width: 206px;
  color: #0059C7;
}

.Spouse-btn:hover {
  background-color: #0059C7;
  color: white;
}

.main-box {
  border: 1px solid #DEE1E6;
  border-radius: 30px;
  justify-content: center;
  display: flex;
}

.Spouse-add {
  border: 1px solid #0059C7;
  border-radius: 100px;
  height: 40px;
  width: 117px;
  color: #0059C7;
}

.Spouse-add:hover {
  background-color: #0059C7;
  color: white;
}



/* card */

/* .carousel {
  position: relative;
}

.card-carousel .carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  background-color: black;
}


.card-carousel .carousel-indicators {
  position: absolute;
  right: 0;
  top: 35rem;
  left: 0;
  top: 35rem;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  background-color: blue;
}

.card-carousel .carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #000;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease;
}

.card-carousel .carousel-indicators .active {
  opacity: 1;
}

.card-carousel .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.card-carousel .carousel-control-prev {
  position: absolute;
  top: 16rem;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: .5;
  transition: opacity .15s ease;
}

.card-carousel .carousel-control-prev {
  left: 0;
}

.card-carousel.carousel-control-next {
  right: 0;
}

.card-carousel .carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 17rem;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: .5;
  transition: opacity .15s ease;
}

.card-carousel .carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.flickity-viewport {
  height: 189.109px;
  touch-action: pan-y;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  transform: translateX(37.25%);
}

.carousel-inner {
  position: relative;
  width: 100%;
  top: 140px;
  overflow: hidden;
}

.carousel-cell is-selected {
  position: absolute;
  left: 0%;
}

.card-carousel .carousel .carousel-cell.is-selected {
  transform: scale(1.2);
}

.card-carousel .carousel .carousel-cell {
  padding: 10px;
  background-color: #FFFFFF;
  width: 20%;
  height: auto;
  min-width: 120px;
  margin: 0 20px;
  transition: transform 500ms ease;
} */

.card {
  width:  310px !important;
  border-radius: 16px;
  overflow: hidden;
  margin: 20px;
  height: 370px !important;
  border: none !important;
}

.card-content {
  padding: 20px;
  background-color: #EFF4FA;
  border-radius: 16px;
}


/* card */


.card-btn {
  border: 1px sold;
  border-radius: 4px;
  background-color: #4F8CE8;
  text-align: center;
  height: 37px;
  padding: 6px;
}

.subscriprtion-btn {
  border-radius: 4px;
  background-color: #4F8CE8;
  text-align: center;
  height: 37px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
}

.slick-center .card {
  transform: scale(1.0);
  border: 3px solid #7DAAED !important;
  border-radius: 16px !important;
  height: 370px !important;
  width: 310px !important;
  background-color: #EFF4FA;
}


.slick-dots li button:before {
  font-family: 'slick';
  font-size: 16px !important;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: #7DAAED !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots {
  top: 30rem;
}

.slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: none !important;
}

.FeatureTxt-subscription {
  font-size: 13px;
  color: #000000;
  font-weight: 600;
}

.subscription-right {
  color: #000000;
  width: 16px;
  height: 19px;
  position: relative;
  bottom: 11px;
}

.subscriptionPeriodTxt {
  color: #3C4043;
  font-size: 16px;
  font-weight: 600;
}

.subscription-AmountTxt {
  color: #000000;
  font-size: 29px;
  font-family: 600;
}

.card-sub {
  padding-top: 60px;
}

.subscription-pointtxt{
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  padding-top: 4px;
}

.sign-up-checkbox-icon {
  width: 70%;
  height: 50px;
}

.pl-0 {
  padding-left: 0 !important;
}

.text-center {
  text-align: center;
}
